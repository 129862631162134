@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

:root {
    --bgColor: rgb(236 70 19);
    --bgColor2nd: #ec671b;
    --bgImageColor: #F6931F;
    --textColor: #0c0a0a;
    --textColor2nd: #1eb2a6;
    --blackColor: rgba(5, 5, 5, 0.8);
    --greyColor: #999999;
    --whiteColor: #ffffff;
    --icongrey: rgba(255, 255, 255, 0.2);
    --footerGrey: #EEEEEE;
    --footerLightBlack: #3a3a3a;
    --footerBlack: #000000;
    --innerfooterGrey: #E9E9E9;
    --halfwhite: #F7F5F6;
    --headerBg: #e6e6e6;
}


body {
    /* background-color: white !important; */
    background-color: #f1f3f5 !important;
    /* font-family: 'Roboto', sans-serif; */
    font-family: unset !important;
    transition: all 500ms ease;
}

.upload_color>.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-text .ant-upload-list-item-name {
    color: #ec671b;
}

html {
    --antd-wave-shadow-color: none !important;
}

/* Tournament Graph */

.playoff-table * {
    box-sizing: border-box;
}

.playoff-table {
    font-family: sans-serif;
    font-size: 15px;
    line-height: 1.42857143;
    font-weight: 400;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    background-color: #f5f5f5;
}

.playoff-table .playoff-table-content {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    padding: 20px;
}

.playoff-table .playoff-table-tour {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    -ms-justify-content: space-around;
    justify-content: space-around;
    position: relative;
}

.playoff-table .playoff-table-pair {
    position: relative;
}

.playoff-table .playoff-table-pair:before {
    content: '';
    position: absolute;
    top: 27px;
    right: -12px;
    width: 12px;
    height: 1px;
    background-color: var(--bgColor);
}

.playoff-table .playoff-table-pair-winner:before {
    content: '';
    position: absolute;
    top: 27px;
    right: -12px;
    width: 12px;
    height: 1px;
}

.playoff-table .playoff-table-pair:after {
    content: '';
    position: absolute;
    width: 3px;
    height: 1000px;
    background-color: #f5f5f5;
    right: -12px;
    z-index: 1;
}

.playoff-table .playoff-table-pair:nth-child(even):after {
    top: 28px;
}

.playoff-table .playoff-table-pair:nth-child(odd):after {
    bottom: 28px;
}

.playoff-table .playoff-table-pair-style {
    border: 1px solid #cccccc;
    background-color: white;
    width: 160px;
    margin-bottom: 20px;
}

.playoff-table .playoff-table-group {
    padding-right: 11px;
    padding-left: 10px;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    height: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    -ms-justify-content: space-around;
    justify-content: space-around;
}

.playoff-table .playoff-table-group .playoff-table-pair-style:last-child {
    margin-bottom: 0px;
}

.playoff-table .playoff-table-group:after {
    content: '';
    position: absolute;
    top: 28px;
    bottom: 29px;
    right: 0px;
    width: 1px;
    background-color: var(--bgColor);
}

.playoff-table .playoff-table-group:last-child {
    margin-bottom: 0;
}

.playoff-table .playoff-table-left-player,
.playoff-table .playoff-table-right-player {
    min-height: 26px;
    padding: 3px 5px;
}

.playoff-table .playoff-table-left-player {
    border-bottom: 1px solid #cccccc;
}

.playoff-table .playoff-table-left-player:before {
    content: '';
    position: absolute;
    bottom: 27px;
    left: -12px;
    width: 12px;
    height: 1px;
    background-color: red;
}

.playoff-table .playoff-table-left-player-winner:before {
    content: '';
    position: absolute;
    bottom: 27px;
    left: -12px;
    width: 12px;
    height: 1px;
    /* background-color: red; */
}

.playoff-table .playoff-table-right-player {
    margin-top: -1px;
    border-top: 1px solid #cccccc;
}

.playoff-table .playoff-table-third-place {
    position: absolute;
    left: 11px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 100px;
}

.playoff-table .playoff-table-third-place .playoff-table-left-player:before {
    display: none;
}

.playoff-table .playoff-table-tour:first-child .playoff-table-group {
    padding-left: 0;
}

.playoff-table .playoff-table-tour:first-child .playoff-table-left-player:before {
    display: none;
}

.playoff-table .playoff-table-tour:last-child .playoff-table-group:after {
    display: none;
}

.playoff-table .playoff-table-tour:last-child .playoff-table-pair:after,
.playoff-table .playoff-table-tour:last-child .playoff-table-pair:before {
    display: none;
}


/* winner */

.playoff-table .playoff-table-tour {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    -ms-justify-content: space-around;
    justify-content: space-around;
    position: relative;
}

.playoff-table .playoff-table-pair-winner {
    position: relative;
}

.playoff-table .playoff-table-pair-winner:before {
    content: '';
    position: absolute;
    top: 27px;
    right: -12px;
    width: 12px;
    height: 1px;
    /* background-color: red; */
}

.playoff-table .playoff-table-pair-winner:after {
    content: '';
    position: absolute;
    width: 3px;
    height: 1000px;
    background-color: #f5f5f5;
    right: -12px;
    z-index: 1;
}

.playoff-table .playoff-table-pair-winner:nth-child(even):after {
    top: 28px;
}

.playoff-table .playoff-table-pair-winner:nth-child(odd):after {
    bottom: 28px;
}

.playoff-table .playoff-table-pair-style {
    border: 1px solid #cccccc;
    background-color: white;
    width: 160px;
    margin-bottom: 20px;
}

.playoff-table .playoff-table-group-winner {
    padding-right: 11px;
    padding-left: 10px;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    height: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    -ms-justify-content: space-around;
    justify-content: space-around;
}

.playoff-table .playoff-table-group-winner .playoff-table-pair-style:last-child {
    margin-bottom: 0px;
}

.playoff-table .playoff-table-group-winner:after {
    content: '';
    position: absolute;
    top: 28px;
    bottom: 29px;
    right: 0px;
    width: 1px;
    /* background-color: red; */
}

.playoff-table .playoff-table-group-winner:last-child {
    margin-bottom: 0;
}

.playoff-table .playoff-table-left-player-winner,
.playoff-table .playoff-table-right-player {
    min-height: 26px;
    padding: 3px 5px;
}

.playoff-table .playoff-table-left-player-winner {
    border-bottom: 1px solid #cccccc;
}

.playoff-table .playoff-table-left-player-winner:before {
    content: '';
    position: absolute;
    bottom: 27px;
    left: -12px;
    width: 12px;
    height: 1px;
    /* background-color: red; */
}

.playoff-table .playoff-table-right-player {
    margin-top: -1px;
    border-top: 1px solid #cccccc;
}

.playoff-table .playoff-table-third-place {
    position: absolute;
    left: 11px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 100px;
}

.playoff-table .playoff-table-third-place .playoff-table-left-player-winner:before {
    display: none;
}

.playoff-table .playoff-table-tour:first-child .playoff-table-group-winner {
    padding-left: 0;
}

.playoff-table .playoff-table-tour:first-child .playoff-table-left-player-winner:before {
    display: none;
}

.playoff-table .playoff-table-tour:last-child .playoff-table-group-winner:after {
    display: none;
}

.playoff-table .playoff-table-tour:last-child .playoff-table-pair-winner:after,
.playoff-table .playoff-table-tour:last-child .playoff-table-pair-winner:before {
    display: none;
}

/* winner */
/*# sourceMappingURL=playoff-table.css.map */

.helper-main {
    background-color: rgb(0, 0, 0);
}

.card {
    background-color: #ffffff;
    border: 1px solid #e0e0ee;
    width: 35%;
    padding: 40px 30px 20px;
    border-radius: 5px;
    transition: all 500ms ease;
    /* margin: 50px 0 10px; */
}

.card-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.heading {
    font-size: 24px;
    font-weight: bold;
}

.break-line {
    border-top: 0.5px solid #0000003a;
    margin: 0 -30px;
}

.setting-heading {
    font-size: 20px;
    font-weight: 900;
    margin-top: 10px;
    margin-bottom: 5px;
}

.head-center {
    text-align: center;
}

.form-input {
    border-radius: 4px !important;
}

.form-button {
    border-radius: 4px !important;
    /* background-color: #4bb146 !important; */
    color: #ffffff !important;
    /* border: 1px solid #4bb146; */
}

.menu-flex {
    display: flex;
    flex-direction: row;
}

.helper-comp {
    position: relative;
    width: 100%;
}

.home-main {
    height: calc(100vh - 60px);
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.form-button:hover {
    border-color: #4bb146 !important;
}

.linkStyle {
    color: var(--bgColor2nd);
    cursor: pointer;
    font-weight: bolder;
}

.linkStyle :hover {
    color: white !important;
    cursor: pointer !important
}


.auth-screen-main-div {
    padding: 30px 10% !important;
    overflow: auto;
}

.auth-screen-main-div-new {
    padding: 30px 10% !important;
    overflow: auto;
    background-color: var(--blackColor);
    height: calc(100vh - 60px);
}

.form-two-input-flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
}

.form-two-input-flex-inner-input {
    width: 49%;
    padding: 5px;
    margin-top: 5px;
    border-radius: 4px;
}

.form-two-input-flex-inner-input .ant-input-number-affix-wrapper {
    -webkit-padding-start: 0px !important;
}

.form-two-input-flex-inner-input .ant-input-number-affix-wrapper input.ant-input-number-input {
    padding: 10px !important;
    height: 100% !important;
    color: white !important;
}


.form-two-input-flex-inner-input .ant-upload span button {
    width: 100% !important;
    height: 60px !important;
    border-radius: 6px !important;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px !important;
    border: none !important;
}

.form-two-input-flex-inner-input .ant-upload span button:hover {
    width: 100% !important;
    height: 60px !important;
    border-radius: 6px !important;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px !important;
    border: none !important;
    color: black !important;
}

.form-two-input-flex-inner-input .ant-upload span button:focus {
    color: black !important;
    width: 100% !important;
    height: 60px !important;
    border-radius: 6px !important;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px !important;
    border: none !important;
}


.form-two-input-flex-inner-input .ant-upload {
    width: 100% !important;
    height: 65px !important;
}

.form-input-new-style {
    border-radius: 6px !important;
    height: 55px !important;
    border: none !important;
    box-shadow: rgb(0 0 0 / 24%) 0px 2.5px 8px;
}


.form-input-new-style:focus {
    border: none !important;
}

.form-input-new-style.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 6px !important;
    border: none !important;
    padding: 6px !important;
    height: 100% !important;
    padding: 12px !important;
}

.form-input-new-style.ant-select:not(.ant-select-customize-input) .ant-select-selector :focus {
    border: none !important;
}

.form-input-new-style .ant-input-group .ant-input-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
    border: none !important;
    height: 60px !important;
}

.form-input-new-style .ant-input-group .ant-input-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:first-child) :hover {
    border: none !important;
    height: 60px !important;
}

.form-input-new-style .ant-input-group .ant-input-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:first-child) :focus {
    border: none !important;
    height: 60px !important;
}

.newInpuHeightFull .ant-input-number-input-wrap {
    height: 100% !important;
}

.newInpuHeightFull-withMargin .ant-input-number-input-wrap {
    height: 100% !important;
    padding-top: 10px !important;
}



.form-input-new-style-sufix .ant-input-group {
    height: 56px !important;
}

.form-input-new-style-sufix .ant-input-group-addon {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    border: none !important;
    border-left: 1px solid rgb(0 0 0 / 24%) !important;

}

.form-input-new-style-sufix .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    height: 56px !important;
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
    background-color: white !important;
    border: none !important;
}

.update-btn-text {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: pointer !important;
    background-color: #ec671b !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.addproduct-text {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: pointer !important;
    background-color: #ec671b !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.addproduct-text:hover {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: pointer !important;
    background-color: #e39161 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.addproduct-text:focus {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: pointer !important;
    background-color: #e39161 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.withdrawalbtn {
    font-size: 14px !important;
    line-height: 1.4 !important;
    /* cursor: pointer !important; */
    background-color: #ff0000 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.withdrawalbtn:hover {
    font-size: 14px !important;
    line-height: 1.4 !important;
    /* cursor: pointer !important; */
    background-color: #e36161 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.withdrawalbtn:focus {
    font-size: 14px !important;
    line-height: 1.4 !important;
    /* cursor: pointer !important; */
    background-color: #e36161 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.withdrawaltimeput {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: not-allowed !important;
    background-color: #E36161 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.withdrawaltimeput:hover {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: not-allowed !important;
    background-color: #E36161 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.withdrawaltimeput:focus {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: not-allowed !important;
    background-color: #E36161 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.accept_m_btn {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: pointer !important;
    background-color: #00da99 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.accept_m_btn:hover {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: pointer !important;
    background-color: #00da99 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.accept_m_btn:focus {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: pointer !important;
    background-color: #00da99 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.dissabled-sub {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: not-allowed !important;
    background-color: #ff9854 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.dissabled-sub:hover {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: not-allowed !important;
    background-color: #ff9854 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.dissabled-sub:focus {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: not-allowed !important;
    background-color: #ff9854 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.i-won-button {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: pointer !important;
    background-color: #0a8316 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.i-won-button:hover {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: pointer !important;
    background-color: #0e981c !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.i-won-button:focus {
    font-size: 14px !important;
    line-height: 1.4 !important;
    cursor: pointer !important;
    background-color: #0e981c !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}

.i-won-button-dis {
    font-size: 14px !important;
    line-height: 1.4 !important;
    background-color: #0a8316 !important;
    color: white !important;
    padding: 5px !important;
    border-radius: 4px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border: none !important;
}


.input-none div span input {
    height: 55px !important;
}

.screen-heading {
    font-size: 25px;
    font-weight: 500;
    line-height: 1.4;
    cursor: pointer;
    color: #ec671b;
}

.div-flex-back-name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.screen-back-icion {
    height: 35px;
    margin-right: 14px;
    cursor: pointer
}

.height-35 {
    height: 35px;
}

.footer-text {
    color: white;
    cursor: pointer;
    margin: 0px;

}

.countries-select-span {
    margin-top: 10px !important;
    padding-bottom: 9px;
}

.countries-select>button {
    border: none !important;
    background-color: white;
    width: 60px;
    height: 60px;
    padding: 0px;
    padding-left: 5px;
    padding-right: 5px;
}

.countries-select-grey>button {
    border: none !important;
    background-color: var(--bgColor2nd);
    width: 60px;
    padding: 0px;
    padding-left: 5px;
    padding-right: 5px;
}

.countries-select-update-span {
    margin-top: 10px !important;
    padding-bottom: 9px;
}

.countries-select-update>button {
    border: none !important;
    background-color: white;
    width: 60px;
    padding: 0px;
    padding-left: 5px;
    padding-right: 5px;
}

.verify-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.verify-text {
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: #ec671b;
    ;
}

.verify-head {
    margin-bottom: -10px;
    text-align: center;
    font-size: 80px;
    font-weight: 600;
    color: #ec671b;
    ;
}

.verify-div {
    height: 100vh;
    background-image: -webkit-linear-gradient(top, #dfedf0, #e6dada);
    background-image: linear-gradient(180deg, #dfedf0, #e6dada);
}

/* scroll  */
.sidebar-scroll_class::-webkit-scrollbar {
    width: 4.5px !important;
    height: 3.5px !important;
    border-radius: 4px !important;
    background-color: rgb(245, 159, 133) !important;
}

.sidebar-scroll_class::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 15px;
    height: 3.5px !important;
    background-color: transparent;
}

.sidebar-scroll_class::-webkit-scrollbar-thumb {
    /* background: var(--color3) !important; */
    background: #ec671b !important;
    border-radius: 10px !important;
    height: 2px !important;
    /* display: none !important; */
}


/* scroll  rgb(92, 93, 93)*/
.sidebar-scroll-class-black::-webkit-scrollbar {
    width: 4.5px !important;
    height: 3.5px !important;
    border-radius: 4px !important;
    background-color: rgba(55, 184, 60, 0) !important;
}

.sidebar-scroll-class-black::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 15px;
    height: 3.5px !important;
    background-color: transparent !important;
}

.sidebar-scroll-class-black::-webkit-scrollbar-thumb {
    background: rgb(92, 93, 93) !important;
    border-radius: 10px !important;
    height: 2px !important;
}

.new-layout-scroll-class::-webkit-scrollbar {
    width: 4.5px !important;
    height: 3.5px !important;
    border-radius: 4px !important;
    background-color: rgba(255, 255, 255, 0.297) !important;
}

.new-layout-scroll-class::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 15px;
    height: 3.5px !important;
    background-color: rgba(255, 255, 255, 0.297) !important;
}

.new-layout-scroll-class::-webkit-scrollbar-thumb {
    background: var(--whiteColor) !important;
    border-radius: 10px !important;
    height: 2px !important;
}


/* side menu  */

.home-main {
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: white;
}

.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}








/* ////////////////////////////////////////////////NEWNEWNEWNWENWENWENWENWNEWNE */

.new-side-menu.ant-layout-sider-has-trigger {
    padding: 0px !important;
    background-color: white;
}

.new-side-menu>.ant-layout-sider-trigger {
    background-color: #ec671b !important;
    display: none !important;
}

.new-side-menu>.ant-layout-sider-children>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent !important;
    color: #ec671b !important;
    border-radius: 10px !important;
    border-right: 6px solid #ec671b !important;
}

.new-side-menu>.ant-layout-sider-children>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:hover {
    background-color: transparent !important;
    color: #ec671b !important;
    border-radius: 10px !important;
    border-right: 6px solid #ec671b !important;
}

.new-side-menu>.ant-layout-sider-children>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item a:hover {
    color: #ec671b !important;
}

.new-side-menu>.ant-layout-sider-children>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a,
.new-side-menu>.ant-layout-sider-children>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a:hover {
    color: #ec671b !important;
}

.new-side-menu>.ant-layout-sider-children>.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu:hover {
    color: #ec671b !important;
}

.new-side-menu>.ant-layout-sider-children>.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-arrow {
    color: #ec671b !important;
}


.my-menu-sh-new .ant-menu-submenu-title:hover {
    color: #ec671b !important;
}

.my-menu-sh-new .ant-menu-item-selected .ant-menu-title-content a {
    background-color: transparent !important;
    color: #ec671b !important;
    border-radius: 10px !important;
    /* background-color: white !important; */
}

.my-menu-sh-new .ant-menu-item-selected {
    background-color: transparent !important;
    color: #ec671b !important;
    border-radius: 10px !important;
    background-color: white !important;
    border-right: 6px solid #ec671b !important;
}

.my-menu-sh-new .ant-menu-submenu-selected {
    background-color: transparent !important;
    color: #ec671b !important;
    border-radius: 10px !important;
    background-color: white !important;
}

.side-list {
    font-size: 18px;
    transition: 0.3s !important;
}

.ant-menu-inline .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-vertical .ant-menu-item:after {
    border: none !important;
}

/* side menu  */
.admin-home-title {
    font-size: 24px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #ec671b;
    font-weight: 600;
    margin: 0px;
    line-height: 1.4;
}

.admin-home-title-white {
    font-size: 26px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #ffffff;
    font-weight: 600;
    margin: 0px;
    margin-left: 1%;
    line-height: 1.4;
    text-shadow: 3px 4px 7px rgba(0, 0, 0, 0.8);
}

.desktop-head {
    display: flex;
}

.mobile-head {
    display: none;
}

.button-div-flex-end {
    height: auto;
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.footer-img {
    display: none !important;
}

.p_button_style {
    border: 2px solid rgb(236, 103, 27);
    color: rgb(236, 103, 27);
    padding: 2.5px;
    width: 80px;
    text-align: center;
    border-radius: 8px;
    margin: 0px;
}

/*  */

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important;
}

.form-input-new-style-select-minhi {
    min-height: 45px !important;
    border-radius: 0px !important;
    border: none !important;
    border-bottom: 1px solid var(--bgColor2nd) !important;
}

.form-input-new-style-select-minhi:focus {
    border: none !important;
}

.form-input-new-style-select-minhi.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 6px !important;
    border: none !important;
    height: 100% !important;
    padding: 0px !important;
}

.form-input-new-style-select-minhi.ant-select:not(.ant-select-customize-input) .ant-select-selector :focus {
    border: none !important;
}

.p-input-style {
    padding: 3px;
    width: 100px;
    height: 29px;
    margin: 0px;
    padding-left: 6px;
    border: 1px solid #EC671B;
    border-radius: 3px;
    cursor: pointer;
}

.accept-status {
    padding: 1px 14px;
    color: green;
    background: #4bff0047;
    margin: 0px;
    border-radius: 20px;
    text-align: center;
}

.reject-status {
    text-align: center;
    padding: 1px 14px;
    margin: 0px;
    background: #ff00003b;
    color: red;
    border-radius: 20px;
}

.pending-stats {
    text-align: center;
    margin: 0px;
    padding: 1px 14px;
    color: #9100ff;
    background: #cc00ff26;
    border-radius: 20px;
}

.table_p {
    margin: 0px;
}

.promoter-form {
    width: 900px
}

.p-none-margin {
    margin: 0px;
    display: flex;
}

.p-none-margin-bolder {
    margin: 0px;
    font-weight: bolder;
}

.border-line {
    border-bottom: 2px dotted #ec671b;
    margin-top: 10px;
    margin-bottom: 10px
}

.p-none-margin-bolder-p {
    display: flex;
    justify-content: center;
    margin: 0px;
    font-weight: bolder;
    margin-top: 10px;
    color: #ec671b;
}

.same-button-not-allowed {
    height: 45px;
    width: 180px;
    margin-top: 10px;
    border: none;
    color: white;
    background-color: rgb(236, 103, 27);
    border-radius: 4px;
    cursor: not-allowed;
}

.custom-nodata-p {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    color: gray;
    font-size: 16px;
}

.reward-point-box {
    box-shadow: 0 0px 6px 3px rgba(0, 0, 0, 0.2);
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 16px;
    text-align: center;
    width: 100%;
    border-radius: 8px;
    background-color: white;
    /* style={{
        // backgroundColor: '#f1f1f1',
    }} */
}

.ant-switch-checked {
    background-color: #ec671b !important;
}

.ant-switch {
    border: 1px solid var(--bgColor) !important;
}

.p-question {
    font-size: 20px;
    margin-bottom: 2px;
    text-align: center;
    background: var(--footerLightBlack);
    color: #ec671b;
    padding: 20px 0px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.p-opt {
    margin: 0px;
    font-size: 15px;
    margin-left: 4px;
    font-weight: bold;
}

.pop-info-modal .ant-modal-content {
    border-radius: 20px !important;
}

.pop-info-modal .ant-modal-close-x {
    display: none !important;

}

.pop-info-modal .ant-modal-body {
    padding: 8px !important;

}

.form-div-in-modal {
    background: var(--footerLightBlack);
    border-bottom-right-radius: 14px;
    border-bottom-left-radius: 14px;
    padding: 10px;
}

.cancel-btn {
    width: 50%;
    text-align: center;
    border: none;
    background-color: white;
    color: #ec671b;
    height: 40px;
    border: 1px solid #ec671b;
    cursor: pointer;
}

.confirm-btn {
    cursor: pointer;
    width: 50%;
    text-align: center;
    border: none;
    color: white;
    background-color: #ec671b;
    height: 40px;
}

.p-promoted {
    background: #ec671b4a;
    padding: 2px 10px;
    color: #ec671b;
    border-radius: 15px;
    width: 110px;
    text-align: center;
    margin: 0px;
}

.p-not-promoted {
    background: #d51bec52;
    padding: 2px 10px;
    color: #d51bec;
    border-radius: 15px;
    width: 110px;
    text-align: center;
    margin: 0px;
}


.subscription-title-new {
    font-size: 22px;
    font-weight: 500;
    transition: 1s;
    /* border-bottom: 1px solid rgba(100, 100, 100, 0.3); */
}

.modal_width {
    min-width: 70% !important;
}

.border-bottom-dotted {
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 2px dotted #ec671b
}

.input12345 .ant-input-number {
    height: 43px;
    padding-top: 12px;
    padding-left: 6px;
    box-shadow: none;
}

/* /////////// */
.ranking-main-div {
    background-color: black;
    background-image: url(./assets/Rpoint.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.pro-ranking-main-div {
    background-color: black;
    background-image: url(./assets/PrPoint.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.create-match-req-main-div {
    background-color: black;
    background-image: url(./assets/create-match.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.match-req-main-div {
    background-color: black;
    background-image: url(./assets/match-request-s.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.home-main-div {
    background-color: black;
    background-image: url(./assets/home.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.match-result-main-div {
    background-color: black;
    background-image: url(./assets/MatchResult.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.tournament-match-result-main-div {
    background-color: black;
    background-image: url(./assets/tournament-result.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}



.tournamnent-select-main-div {
    background-color: black;
    background-image: url(./assets/tournamentSelect.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.tournamnent-info-main-div {
    background-color: black;
    background-image: url(./assets/tournamentInfo.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.create-tournament-main-div {
    background-color: black;
    background-image: url(./assets/crt-tournament.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.subscription-main-div {
    background-color: black;
    background-image: url(./assets/subscription-new.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.profile-main-div {
    background-color: black;
    background-image: url(./assets/profile.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.claim-p-main-div {
    background-color: black;
    background-image: url(./assets/claim.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.contact-p-main-div {
    background-color: black;
    background-image: url(./assets/contact.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.game-p-main-div {
    background-color: black;
    background-image: url(./assets/new-game.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.game-sugg-p-main-div {
    background-color: black;
    background-image: url(./assets/game-sugg.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.invite-friend-p-main-div {
    background-color: black;
    background-image: url(./assets/invtFriend.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.cash-back-p-main-div {
    background-color: black;
    background-image: url(./assets/c-back.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.wallet-p-main-div {
    background-color: black;
    background-image: url(./assets/wl-tr.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}

.faq-all-main-div {
    background-color: black;
    background-image: url(./assets/faq.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: calc(100%);
    padding: 2px;
}


.input12345>.ant-input-number-prefix {
    color: white !important;
}

/* AUTH SCREEN  */

.login-main-screen {
    background-color: black;
    background-image: url(./assets/login-new.jpeg);
    background-size: 100% auto;
    background-repeat: repeat;
    height: calc(100%);
    padding: 2px;
}

.signup-main-screen {
    background-color: black;
    background-image: url(./assets/signup-new.jpeg);
    background-size: 100%;
    background-repeat: repeat;
    height: calc(100%);
    padding: 2px;
}

.forgot-password-main {
    background-color: black;
    background-image: url(./assets/forgot-new.jpeg);
    background-size: 100%;
    background-repeat: repeat;
    height: calc(100%);
    padding: 2px;
}

.admin-login-main {
    background-color: black;
    background-image: url(./assets/admin-login.jpg);
    background-size: 100%;
    background-repeat: repeat;
    height: calc(100%);
    padding: 2px;
}


.tableValue {
    margin: 0px;
    width: 19%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
    text-shadow: 3px 4px 7px rgba(81, 67, 21, 0.8);
}

.my-form-label {
    color: white;
    margin: 0px;
    font-size: 16;
    font-weight: bold;
    text-shadow: 3px 4px 7px rgba(81, 67, 21, 0.8);
}

.my-form-label-new {
    color: var(--bgColor2nd);
    margin: 0px;
    font-size: 16px;
    font-weight: bold;
}

.white-label {
    color: white;
}

.table-p {
    width: 250px;
    margin-left: 10px;
    margin-right: 10px
}


.new-btton {
    padding: 0px 20px 0px 20px;
    background: rgba(92, 93, 93, 1);
    color: white;
    border: none;
    height: 45px;
    margin-top: 10px;
    border-radius: 6
}

.p-admin-normal-text {
    color: #ec671b;
    font-size: 14px;
    font-weight: bold;
    margin: 0px;
}

.p-admin-title-text {
    margin: 0px;
    color: #ec671b;
    font-size: 15px;
    font-weight: bolder;
}

.input-placeholder-orange input::placeholder {
    color: #ec671b;
}

.input-placeholder-orange input {
    color: #ec671b;
}

.select-placeholder-orange .ant-select-selector .ant-select-selection-placeholder {
    color: #ec671b;
}

.select-placeholder-orange .ant-select-selector .ant-select {
    color: #ec671b;
}

.date-placeholder-orange .ant-picker-input input::placeholder {
    color: #ec671b;
}

.time-placeholder-orange .ant-picker-input input::placeholder {
    color: #ec671b;
}

.font-math {
    font-family: Arial, Helvetica, sans-serif;
    word-spacing: -2px;
}

.login-s-button {
    padding-left: 22px;
    padding-right: 22px;
    height: 39px;
    border-radius: 6px;
    border: none;
    margin-right: 10px;
    font-weight: bold;
    cursor: pointer;
}

.home-1st-div {
    border: 2px solid white;
    height: 250px;
    width: 95%;
    margin: 50px auto 0px;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
}

.txt-div-home {
    margin-top: 30px;
    margin-left: 20px;
}

.home-soldier-img {
    margin-top: -120px;
}

.home-button-div {
    margin-top: 20px;
}

.ppp-box {
    padding: 10px;
    border-radius: 11px;
    margin-top: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #f1f1f1;
}

.main-title-result {
    color: var(--blackColor);
    font-size: 22px;
    cursor: pointer;
    margin-left: 4px;
}

/* .textarea-placeholder-orange {
    color: #ec671b;
} */

.new-design-header {
    padding: 10px;
    background-color: #e6e6e621;
}

.new-designer-inner {
    width: 94%;
    height: 140px;
    margin: 0px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.new-header-img-desk {
    height: 140px;
    margin-left: -70px;
}

.new-header-img-mobile {
    height: 65px;
    display: none;
    margin-left: -10px;
}

.new-small-upper-header {
    background-color: var(--blackColor);
}

.ant-input-password-icon.anticon {
    color: var(--whiteColor) !important;
}

input[type=number],
input[type=password],
input[type=text],
textarea {
    color: white !important;
}


.new-small-upper-header-inner {
    width: 94%;
    height: 160px;
    margin: 0px auto;
    height: 60px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.new-small-upper-header-inner>.name {
    font-weight: bold;
    color: var(--whiteColor);
    font-size: 20px;
    /* margin-left: 50px; */
    cursor: pointer;
}

.new-small-upper-header-inner>.name:hover {
    color: var(--bgColor);
}

.new-small-upper-header-inner>.last-div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.round-countries-select-update>button {
    border: none !important;
    width: 48px !important;
    height: 32px !important;
    margin-top: 5px;
    margin-left: -7px;
    background-color: transparent !important;
    border-radius: 100px !important;
}

.round-countries-select-update>ul {
    margin-left: 6px !important;
    /* height: 80px !important; */
}

.round-countries-select-update>ul>li {
    padding: 5px !important;
    padding-top: 1px !important;
}


.round-countries-select-update>button>span>span>svg>g {
    border-radius: 100px !important;
}

.round-countries-select-update .ReactFlagsSelect-module_selectBtn__19wW7:after,
.ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded="true"]:after {
    display: none !important;
}

.uppr-icon-main {
    margin: 0px;
    margin-top: 5px;
}


.upper-header-icon {
    border-radius: 50%;
    margin-right: 4px;
    background: var(--icongrey);
    padding-top: 16px;
    padding-bottom: 10px;
    padding-right: 12px;
    padding-left: 12px;
    color: var(--bgColor);
    cursor: pointer;
}

.upper-header-icon:hover {
    background: var(--bgColor);
    color: white;
}


.round-lang-upper-header-icon {
    border-radius: 50%;
    margin-right: 4px;
    background: var(--icongrey);
    /* padding-top: 14px; */
    /* padding-bottom: 10px; */
    /* padding-right: 12px; */
    height: 42px;
    /* padding-left: 12px; */
    color: var(--bgColor);
    margin-top: 2px;
}

.round-lang-upper-header-icon:hover {
    background: var(--bgColor);
    color: white;
}

.new-lower-nav {
    height: 115px;
    margin: 0px 22px;
    /* background-color: rgba(255, 255, 255, 0.085); */
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
}


.lower-nav-left {
    display: flex;
    flex-direction: row;
}

.me-new-top-menu {
    padding-top: 6px !important;
    background: transparent !important;
    border: none !important;
    width: calc(100vw - 294px);
}


.my-menu-txtt {
    color: white;
    font-weight: bold;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    margin: 0px;
    display: flex;
}

/* .new-header-sub-menu {
    padding: 20px;
    height: 45px;
} */

.new-header-sub-menu>.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active:after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open:after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover:after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active:after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open:after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected:after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover:after {
    border-bottom: none !important;
    /* background-color: red !important; */
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover {
    background-color: var(--bgColor) !important;
}

.new-menu-c-item-active {
    font-weight: bold;
    color: var(--bgColor);
}

.new-menu-c-item {
    color: var(--whiteColor);
    font-weight: bold;
}

.new-menu-for-mobile {
    display: none;
}

.lower-nav-right {
    display: flex;
    flex-direction: row;
}

.button-triangle {
    width: 100px;
    /* height: 80px; */
    background: var(--bgColor);
    -webkit-transform: skewX(-20deg);
    -ms-transform: skewX(-20deg);
    transform: skewX(-20deg);
    content: '';
    margin: 0px;
    cursor: pointer;
}

.lower-nav-right-bton {
    margin: 0px;
    height: 70px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    background: var(--bgColor);
    padding: 2.5rem;
    color: var(--whiteColor);
    border-radius: 0;
    display: inline-block;
    width: 160px;
    z-index: 0;
    letter-spacing: 2px;
    margin-left: -80px;
    cursor: pointer;
    text-align: center;
}

.ant-menu-vertical-left.ant-menu-sub:not([class*=-active]),
.ant-menu-vertical-right.ant-menu-sub:not([class*=-active]),
.ant-menu-vertical.ant-menu-sub:not([class*=-active]) {
    background-color: var(--bgColor);
}

.ant-menu-submenu-arrow,
.ant-menu-submenu-expand-icon {
    display: none !important;
}

.ant-menu-item .ant-menu-item-only-child .new-header-sub-menu {
    margin-left: 18px;
}

.new-header-men-icon {
    padding: 22px;
    color: #ffffff;
    background: var(--bgColor2nd);
    margin-left: 10px;
    border-radius: 4px;
    cursor: pointer;

}

.my-modal-for-menu {
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
    max-width: 100% !important;
    position: initial !important;
}

.my-modal-for-menu>.ant-modal-content {
    width: 100% !important;
    height: 100vh !important;
    background-color: var(--bgColor2nd) !important;
}

.my-modal-for-menu>.ant-modal-content button {
    display: none !important;
}

.cloxe-div-end {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 40px;
}

.cloxe-div {
    background-color: var(--bgColor2nd);
    border-radius: 4px;
    color: var(--whiteColor);
    height: 40px;
    width: 40px;
    text-align: center;
    padding-top: 2px;
    cursor: pointer;
}

.my-modal-menu-div {
    height: calc(100vh - 140px);
    overflow-y: auto;
}

.upper-header-txt-logo-menu {
    font-weight: 800;
    font-size: 30px;
    color: var(--whiteColor);
    z-index: 3;
    position: relative;
    line-height: 1.1;
    text-transform: uppercase;
    margin: 0px;
    cursor: pointer;
}

.upper-header-txt-logo-menu span {
    color: var(--whiteColor);
    line-height: 1.1;
    text-transform: uppercase;
    font-size: 12px;
    display: block;
    font-weight: 500;
}

.new-main-layout-div {
    height: 100vh;
    background-color: var(--blackColor);
}

.new-main-layout-div {
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url(./assets/bg-slaim.jpg);
}

.new-footer-main {
    background-color: var(--whiteColor);
    width: 100%;
}

.first-footer {
    padding: 15px 5px;
    background-color: var(--footerLightBlack);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.second-footer {
    background-color: var(--footerBlack);
    display: flex;
    justify-content: space-between;
    padding: 20px 3%;
    align-items: center;
    flex-wrap: wrap;
}

.last-footer-txt {
    color: var(--bgColor);
    font-weight: bold;
    font-size: 14px;
}

.footer-icon-main {
    margin-top: 20px;
}

.footer-header-icon {
    border-radius: 50%;
    margin-right: 4px;
    background: var(--bgColor);
    padding-top: 14px;
    padding-bottom: 10px;
    padding-right: 12px;
    padding-left: 12px;
    color: var(--whiteColor);
    cursor: pointer;
}

.footer-inner-card {
    width: 25%;
    padding: 20px;
}

.title-inner-footer {
    font-size: 14px;
    letter-spacing: 1px;
    color: white;
    text-transform: uppercase;
}

.footer-inner-span-flex-icon-tetx {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 15px;
}

.footer-inner-span-flex-tetx {
    letter-spacing: 1px;
    color: white;
    margin-left: 8px;
}

.footer-nav {
    margin-right: 30px;
    color: var(--whiteColor);
    font-weight: bold;
}

.span-bottom-recent-post {
    width: 100%;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    color: var(--bgColor2nd);
    border-bottom: 1px solid var(--bgColor2nd);
}

.footer-logo-end {
    height: 80px;
    width: 140px;
}

.footer-logo-decs-end {
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: var(--footerGrey);
    font-weight: 500;
}

.center {
    text-align: center;
}

.div-for-text-transition {
    height: 350px;
    padding: 0px 30px;
    display: flex;
    align-items: center;
}

.div-flex-colum {
    display: flex;
    flex-direction: column
}


.smaill-1st-heaader-text {
    color: var(--bgColor2nd);
    font-size: 12px;
    font-weight: bold;
}

.smaill-2nd-orange-heaader-text {
    color: var(--bgColor2nd);
    font-size: 72px;
    font-weight: bold;
}

.smaill-2nd-white-heaader-text {
    color: var(--whiteColor);
    font-size: 72px;
    font-weight: bold;
}

.smaill-3rd-white-heaader-text {
    color: var(--whiteColor);
    font-size: 22px;
    font-weight: bold;
}

.margin-mins-20 {
    margin-top: -20px;
    margin-bottom: -20px;
}

.new-main-component-screen-div {
    padding: 10px 30px;
    background-color: var(--whiteColor);
}

.main-2nd-div-new-layout {
    height: 100vh;
    overflow-y: auto;
}

.new-screen-title {
    font-weight: 800;
    font-size: 30px;
    color: var(--blackColor);
    z-index: 3;
    position: relative;
    line-height: 1.1;
    text-transform: uppercase;
    padding-top: 10px;
    margin: 0px;
}

.new-screen-text-box {
    background-color: #f6921f55;
    width: 100%;
    height: 200px;
    margin-top: 20px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding-top: 30px;
}

.new-home-txts-1st {
    text-align: center;
    color: var(--blackColor);
    font-size: 18px;
    font-weight: bold;
    margin: 0px;
}

.three-boxes-card-div {
    margin-top: -100px;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    padding-left: 10px;
    flex-wrap: wrap;
    justify-content: center;

}

.ex-tion-card {
    width: 32%;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px;
    border-radius: 10px;
    padding: 8px;
    margin: 3px;
    margin-top: 15px;
    background-color: var(--whiteColor);
}

.ex-tion-card:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 8px 4px;
    transform: translateY(-3px);
    transition: opacity 0.5s, transform 0.5s;
}


.div-tt {
    margin-top: 4px;
    margin-bottom: 2px;
    font-weight: bold;
    font-size: 19px;
    background-color: var(--whiteColor);
    border-radius: 10px;
}

.ex-tion-card-button {
    background-color: var(--bgColor2nd);
    color: var(--whiteColor);
    padding: 10px;
    border-radius: 4px;
    width: 100%;
    border: none;
    cursor: pointer;
}

/* new inputs design css betweeen here */
.form-input-new-design-big-slam {
    border-radius: 0px !important;
    height: 55px !important;
    border: none !important;
    border-bottom: 1px solid var(--bgColor2nd) !important;
    background-color: transparent !important;
}

.form-input-new-design-big-slam-cus {
    border: none;
    height: 40px;
    border-bottom: 1px solid var(--bgColor2nd);
}

.form-input-new-design-big-slam.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: none !important;
    border-bottom: 1px solid var(--bgColor2nd) !important;
}

.form-input-new-design-big-slam.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none
}

.ant-input-affix-wrapper-focused {
    box-shadow: none !important;
}

.ant-input-number+.ant-input-number-in-form-item+.form-input-new-design-big-slam+.ant-input-number-focused {
    box-shadow: none !important;
}

.form-input-new-design-big-slam.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid var(--bgColor2nd);
    height: 55px !important;
    padding-top: 12px !important;
    background-color: transparent !important;
    color: white !important;

}

.ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--bgColor) !important;
    color: var(--whiteColor) !important;
    border: none !important;
}


.form-input-new-design-big-slam>.ant-select-arrow {
    color: var(--bgColor2nd) !important;
}

.ant-input-group-addon:first-child {
    border: none !important;
}


.ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border: 1px solid var(--bgColor2nd) !important;
}

.ant-checkbox {
    background-color: var(--bgColor2nd) !important;
    border: 1px solid var(--bgColor2nd) !important;
    border-radius: 2px !important;
}

.ant-checkbox+span:hover {
    color: var(--whiteColor) !important;
}

.ant-checkbox+span {
    color: var(--whiteColor) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    border: 1px solid var(--bgColor2nd) !important;
    background-color: var(--bgColor2nd) !important;
}

.color-bg {
    color: var(--whiteColor);
}

.orang-color {
    color: var(--bgColor);
}


.ant-btn-primary {
    background: var(--bgColor2nd) !important;
    color: white !important;
    border: none !important;
}

.ant-btn {
    max-width: 100%;
    width: auto;
}

.ant-btn-link {
    color: var(--bgColor2nd) !important;
}


.ant-btn-default {
    color: var(--bgColor2nd) !important;
}

.ant-btn-primary:focus,
.ant-btn-primary:hover {
    opacity: 0.6 !important;
}

.ant-btn-link:focus,
.ant-btn-link:hover {
    opacity: 0.6 !important;
}

.ant-btn-default:focus,
.ant-btn-default:hover {
    color: var(--bgColor2nd) !important;
    border-color: var(--bgColor2nd) !important;
    background: #fff;
}

.ant-btn[disabled],
.ant-btn[disabled]:active,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:hover {
    color: rgb(255, 255, 255) !important;
    background: var(--bgColor2nd) !important;
    text-shadow: none !important;
    box-shadow: none !important;

}

.ant-picker-focused,
.ant-picker:hover {
    box-shadow: none !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    color: var(--whiteColor) !important;
    background: var(--bgColor2nd) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
    border: 1px solid var(--bgColor2nd) !important;
}

.ant-picker-footer {
    background-color: var(--bgColor2nd) !important;
}

.ant-picker-today-btn {
    color: var(--whiteColor) !important;

}

.ant-picker-now-btn {
    background-color: var(--whiteColor) !important;
    color: var(--bgColor2nd) !important;
    padding: 4px;
    border-radius: 2px;
}

.ant-radio-wrapper {
    color: var(--whiteColor) !important;
}

.ant-radio-wrapper-disabled span {
    color: var(--whiteColor) !important;
}

/* new inputs design css betweeen here */


.flex-for-addon {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ant-radio:hover .ant-radio-inner {
    border-color: var(--bgColor2nd) !important;
}

.ant-radio-checked .ant-radio-inner {
    border-color: var(--bgColor2nd) !important;
}

.ant-radio-inner:after {
    background-color: var(--bgColor2nd) !important;
}



/* new tavle css */


.new-layout-table thead {
    background-color: var(--blackColor);
    color: var(--whiteColor);
}

.new-layout-table tr:nth-child(2n) td {
    background-color: var(--whiteColor) !important;
}

.new-layout-table tr:nth-child(1n) td {
    background-color: var(--whiteColor) !important;
}

.new-layout-table {
    border-radius: 8px;
    padding: 10px;
    padding-left: 0px;
    padding-right: 0px;
}

.new-layout-table .ant-table-thead>tr>th {
    background: none !important;
    color: white;
    font-weight: bold;
}

.new-layout-table .ant-table-tbody>tr>td {
    border: none !important;
}

/* CustomTable.css */

.custom-table-container {
    width: 100%;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--footerLightBlack) #f5f5f5;
}

.custom-table-container::-webkit-scrollbar {
    height: 2px !important;
}

.custom-table-container::-webkit-scrollbar-thumb {
    background-color: var(--footerLightBlack);
}

.custom-table-container::-webkit-scrollbar-track {
    background-color: #f5f5f5;
}

.ant-input-affix-wrapper>.ant-input:not(textarea) {
    background-color: transparent !important;
    color: var(--whiteColor) !important;
}

.ant-picker-ok button {
    padding: 3px 10px 3px 12px !important;
}

.form-input-new-design-big-slam>.ant-picker-input>input {
    color: white !important;
}

.form-input-new-style-select-minhi.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent !important;
}

.ant-input-group .ant-input-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
    background-color: transparent !important;
    border: none !important;
}

.ant-switch {
    height: 25px !important;
}

.form-two-input-flex-inner-input.newInpuHeightFull.flex-for-addon>div>div>div.ant-col.ant-form-item-control>div>div {
    width: 100%;
    display: flex;
    align-items: center;
}

.form-two-input-flex-inner-input.newInpuHeightFull.flex-for-addon>div>div {
    width: 100%;
}

.form-input-new-design-big-slam .ant-upload span>button {
    background-color: transparent !important;
    color: var(--bgColor) !important;
    border-radius: 0px !important;
    border-bottom: 1px solid var(--bgColor2nd);
}

.form-input-new-design-big-slam .ant-upload span>button:hover {
    color: var(--bgColor) !important;
}

.form-input-new-design-big-slam .ant-upload span>button>svg {
    margin-bottom: 22px !important;
}

.form-input-new-design-big-slam .ant-upload-list.ant-upload-list-text>div>div>div>span>div>span {
    color: var(--bgColor2nd) !important;
}

.form-input-new-design-big-slam div>div {
    background-color: transparent !important;
    color: var(--whiteColor) !important;
    border: none !important;
}

.form-input-new-design-big-slam div>div.ant-input-number-group-addon {
    background-color: transparent !important;
    color: var(--whiteColor) !important;
    border: none !important;
}

.new-new-my-tabs .ant-tabs-tab-active div {
    color: var(--bgColor2nd) !important;
    font-weight: bold;
}

.new-new-my-tabs .ant-tabs-ink-bar {
    background-color: var(--bgColor2nd);
}

.new-new-my-tabs .ant-tabs-tab:hover {
    color: var(--bgColor2nd) !important;
}

.my-menu-new-tab-p {
    font-weight: bold;
    margin-bottom: 0px !important;
}

.my-menu-new-tab-p:hover {
    color: var(--bgColor2nd) !important;
}

.link-custom-orange {
    color: var(--bgColor2nd);
    cursor: pointer;
    width: 110px;
    font-weight: bold;
}

.ant-spin-dot-item {
    background-color: var(--bgColor2nd) !important;
}


/* .new-menu-for-web > ul > li.ant-menu-overflow-item.ant-menu-overflow-item-rest.ant-menu-submenu.ant-menu-submenu-horizontal > div{
    color: white !important;
} */
.new-menu-for-web>ul>li.ant-menu-overflow-item.ant-menu-overflow-item-rest.ant-menu-submenu.ant-menu-submenu-horizontal>div {
    color: var(--whiteColor) !important;
}

.p-text-black {
    color: var(--blackColor) !important;
    margin: 0px;
    font-weight: bold;
}

.login-btn-height {
    height: 45px !important;
}

.img-for-login-page {
    width: 250px;
}

a {
    color: var(--bgColor2nd) !important;
}

.visiter-home-main-div {
    height: 100vh;
}

.visiter-home-main-div-scroll {
    overflow-y: auto;
    height: 100vh;
}


.login-alert-paragraph {
    margin: 0px auto;
    text-align: center;
    color: var(--blackColor);
    cursor: pointer;
    width: fit-content;
}

.login-alert-paragraph>span {
    color: var(--bgColor);
}

.login-alert-paragraph>span:hover {
    color: var(--bgColor2nd);
    font-weight: bold;
    transition: all 500ms ease;
}

.login-alert-paragraph-border {
    border-bottom: 1px solid var(--bgColor);
    margin-top: 20px;
}

.login-alert-paragraph-border>span {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: -11px;
    position: absolute;
    background: var(--whiteColor) !important;
    align-items: center;
    left: 48%;
    width: 22px;
}

.new-p-question {
    font-size: 20px;
    margin-bottom: 2px;
    text-align: center;
    background: #a4c8ce59;
    color: var(--bgColor2nd);
    padding: 20px 0px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.new-div-button {
    padding: 0px 30px;
}

.home-snup-btn {
    background: var(--bgColor2nd) !important;
    border: 1px solid var(--bgColor2nd) !important;
    color: var(--whiteColor) !important;
    padding: 15px 25px 15px 25px;
    cursor: pointer;
    border-radius: 4px;
    -webkit-box-shadow: 0px 24px 36px -11px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 24px 36px -11px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 24px 36px -11px rgba(0, 0, 0, 0.09);
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px
}

.home-snup-btn:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px;
    transform: translateY(-0.8px);
    transition: opacity 0.5s, transform 0.5s;
}

.home-login-btn {
    background: var(--whiteColor) !important;
    border: 1px solid var(--whiteColor) !important;
    color: var(--bgColor2nd) !important;
    padding: 15px 25px 15px 25px;
    cursor: pointer;
    border-radius: 4px;
    -webkit-box-shadow: 0px 24px 36px -11px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 24px 36px -11px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 24px 36px -11px rgba(0, 0, 0, 0.09);
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-left: 10px;
}

.home-login-btn:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px;
    transform: translateY(-0.8px);
    transition: opacity 0.5s, transform 0.5s;
}

.clc-insufficient-balance {
    text-align: center;
    padding-top: 30px;
    color: var(--bgColor2nd);
    font-size: 22px;
    text-shadow: 0px 12PX 20px var(--blackColor);
    font-weight: bold;
}

.clc-balance-info {
    margin-top: -20px;
    text-align: center;
    font-weight: bold;
    color: var(--bgColor2nd);
}

.ant-input:focus {
    border-color: initial !important;
    box-shadow: none !important;
}

.ant-input:focus {
    border-color: initial !important;
    box-shadow: none !important;
}



/* Optional: Remove the outline on button click as well */
.ant-btn:focus {
    outline: none !important;
}



.ant-input-number-handler {
    border-left: 1px solid var(--bgColor2nd) !important;
}

.ant-input-number-focused:not(.ant-input-number-borderless) .ant-input-number-handler-down,
.ant-input-number:hover:not(.ant-input-number-borderless) .ant-input-number-handler-down {
    border-top: 1px solid var(--bgColor) !important;
}

.ant-input-number-handler-down-inner svg,
.ant-input-number-handler-up-inner svg {
    display: inline-block;
    color: var(--bgColor) !important;
}

.form-two-input-flex-inner-input .ant-input-number-affix-wrapper {
    border: none !important;
}

/* #form > div:nth-child(5) > div > div > div > div.ant-col.ant-form-item-control > div > div > div > div > span.ant-select-selection-search */

.new-for-text-top-issue>div>.ant-select-selection-search {
    top: 12px !important;
}

.form-input-new-design-big-slam div>div {
    top: 5px !important;
    position: absolute;
}

.ant-modal {
    top: 5px !important;
}

.ant-select-selection-overflow-item {
    color: var(--bgColor);
}

.ant-menu-item-selected {
    color: blue !important;
}

.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
    background: grey !important;
}

.ant-upload-new-big-slaim>div {
    width: 100% !important;
}

.ant-upload-new-big-slaim>div>span>button {
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--bgColor2nd);
    width: 100% !important;
}

.ant-upload-new-big-slaim>div>span>button:active {
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--bgColor2nd);
    width: 100% !important;
}

.ant-upload-new-big-slaim>div>span>button:hover {
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--bgColor2nd);
    width: 100% !important;
}

.fixed-qr {
    position: fixed;
    top: -700px;
}


.modal-content-div {
    background-color: var(--footerLightBlack);
    padding: 8px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px
}

/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */
/* ///////////////////////// */

@media (max-width: 400px) {

    .new-screen-title {
        font-size: 20px;
    }

    .upper-header-txt-logo-menu {
        font-size: 20px;
        margin-top: 8px;
    }
}

@media (max-width: 900px) {
    .new-menu-for-web {
        display: none !important;

    }

    .new-header-men-icon {
        padding: 7px;
        padding-bottom: 2px;
        margin-top: 6px;
    }

    .new-header-img-desk {
        display: none;
    }

    .new-header-img-mobile {
        display: flex;
    }

    .new-menu-for-mobile {
        display: flex;
    }

    .new-lower-nav {
        justify-content: space-between;
        margin: 0px 10px;
    }


    .new-designer-inner {
        height: 74px;
    }

}

@media (max-width: 800px) {
    .new-main-layout-div {
        background-image: url(./assets/bg-slaim-tab.jpg);
        background-size: 100%;
    }
}

@media (min-width: 1500px) {
    .helper-main {
        width: 1450px;
        justify-content: center;
        margin: 0px auto;
        background-color: rgb(0, 0, 0);
    }

    .new-main-layout-div {
        width: 1450px;
        justify-content: center;
        margin: 0px auto;
    }
}

@media(max-width: 1200px) {
    .card {
        width: 45%;
    }
}

@media(max-width: 1000px) {
    .card {
        width: 60%;
    }
}

@media(max-width: 950px) {
    .promoter-form {
        width: 95%
    }
}

@media(max-width: 900px) {
    .card {
        width: 65%;
    }
}

@media (max-width: 1070px) {
    .footer-inner-card {
        width: 49%;
    }

    .home-1st-div {
        height: auto;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column-reverse;
    }

    .home-img-div {
        display: flex;
        justify-content: center;
    }
}


@media (max-width: 460px) {

    .footer-nav {
        margin-right: 10px;
    }

    .uppr-icon-main {
        display: none;
    }

    .margin-div {
        height: 50px;
    }
}

@media (max-width: 630px) {
    .smaill-2nd-orange-heaader-text {
        font-size: 36px;
    }

    .smaill-2nd-white-heaader-text {
        font-size: 36px;
    }

    .margin-mins-20 {
        margin-top: -10px;
        margin-bottom: -10px;
    }

    .home-soldier-img {
        width: 450px;
        margin-top: -80px;
    }
}

@media (max-width: 460px) {
    .home-soldier-img {
        width: 350px;
    }

    .playersFootbal-img {
        width: 100%;
    }
}

@media (max-width: 850px) {
    .auth-screen-main-div {
        padding: 30px;
        width: 100%;
        margin: 10px auto;
        /* background: #FBFBFB; */
    }

    .auth-screen-main-div-new {
        padding: 30px;
        width: 100%;
        /* background: #FBFBFB; */
    }

}

@media(max-width: 800px) {
    .card {
        width: 70%;
    }
}


@media(max-width: 700px) {
    .second-footer {
        width: 100%;
        justify-content: center;
    }

    .card {
        width: 75%;
    }

    .desktop-head {
        display: none;
    }

    .mobile-head {
        display: flex;
    }

    .footer-img {
        display: flex !important;
    }

    .form-two-input-flex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }

    .form-two-input-flex-inner-input {
        width: 100%;
    }
}

@media (max-width: 750px) {
    .ex-tion-card {
        width: 48%;
    }

    .three-boxes-card-div {
        justify-content: flex-start;
    }

    .login-main-screen {
        background-image: url(./assets/login-new-mobile.jpeg);
    }

    .signup-main-screen {
        background-image: url(./assets/signup-new-mobile.jpeg);
    }

    .forgot-password-main {
        background-image: url(./assets/forgot-new-mobile.jpeg);
    }

    .admin-login-main {
        background-image: url(./assets/admin-login-mobile.jpg);
    }
}


@media (max-width: 950px) {
    .tournamnent-select-main-div {
        background-image: url(./assets/tournamentSelect-M.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .create-match-req-main-div {
        background-image: url(./assets/create-match-mobile.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .match-req-main-div {
        background-image: url(./assets/match-request-s-mobile.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .match-result-main-div {
        background-image: url(./assets/MatchResult-mobile.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .tournament-match-result-main-div {
        background-image: url(./assets/tournament-result-mobile.jpg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .subscription-main-div {
        background-image: url(./assets/Subscription-new-mobile.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .contact-p-main-div {
        background-image: url(./assets/contact-mobile.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .faq-all-main-div {
        background-image: url(./assets/faq-mobile.jpg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .profile-main-div {
        background-image: url(./assets/profile-mobile.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .ranking-main-div {
        background-image: url(./assets/Rpoint-mobile.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .pro-ranking-main-div {
        background-image: url(./assets/PrPoint-mobile.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .claim-p-main-div {
        background-image: url(./assets/Claim-mobile.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .game-p-main-div {
        background-image: url(./assets/new-game-mobile.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .game-sugg-p-main-div {
        background-image: url(./assets/game-sugg-mobile.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .invite-friend-p-main-div {
        background-image: url(./assets/invtFriend-mobile.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .cash-back-p-main-div {
        background-image: url(./assets/c-back-mobile.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .wallet-p-main-div {
        background-image: url(./assets/wl-tr-mobile.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .home-main-div {
        background-image: url(./assets/home-mobile.jpg);
        background-size: 100%;
        background-repeat: repeat;
    }

    .tournamnent-info-main-div {
        background-image: url(./assets/forgot-new-mobile.jpeg);
        background-size: 100%;
        background-repeat: repeat;
    }

}


@media (max-width: 750px) {
    .ex-tion-card {
        width: 98%;
    }

    .new-main-layout-div {
        background-image: url(./assets/bg-slaim-mob.jpg);
        background-size: 100%;
        /* background-size: 100% 100%; */
    }

    .card {
        width: 80%;
        padding: 20px 20px 10px;
    }

    .break-line {
        margin: 0 -20px;
    }

    .modal_width {
        min-width: 70% !important;
    }
}

@media (max-width: 560px) {
    .addproduct-text {
        width: 120px;
    }
}

@media(max-width: 550px) {
    .footer-inner-card {
        width: 100%;
    }
}

@media(max-width: 500px) {
    .card {
        width: 85%;
    }

    .heading {
        font-size: 20px;
    }
}

@media(max-width: 400px) {
    .card {
        width: 90%;
    }

    .new-header-img-mobile {
        height: 50px;
    }
}

@media(max-width: 300px) {
    .card {
        width: 95%;
    }

    .heading {
        font-size: 18px;
    }
}